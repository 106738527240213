import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import PT from 'prop-types'
import {Icon} from 'antd-mobile'
import HeaderNav from '../../components/HeaderNav'
import {closeWindow, isFullScreen as isFullScreenFn} from '../../utils/pwHybrid'
import FullscreenHeaderWrap from '../../components/FullscreenHeaderWrap'
import {isFrdyo, isHuna, isLH, isSocial, isX} from '../../utils/ua'
import './index.scss'

class NotFound extends React.Component {
  state = {
    isFullScreen: false,
  }

  componentDidMount() {
    // 部分安卓机客户端没有收到调用
    setTimeout(() => {
      isFullScreenFn().then(isFull => this.setState({isFullScreen: isFull}))
    }, 200)
  }

  renderHeader = () => (
    <HeaderNav
      title=""
      // pt={pt}
      titleStyle={{
        fontSize: 17,
        fontWeight: 600,
        color: '#000',
      }}
    />
  )

  renderHeaderOld = () => (
    <FullscreenHeaderWrap className="NotFound__headerWrap">
      <div className="NotFound__header">
        <Icon
          type="left"
          size="lg"
          className="NotFound__headerBack"
          // color="#999"
          color="#000"
          onClick={() => {
            closeWindow()
          }}
        />
      </div>
    </FullscreenHeaderWrap>
  )

  render() {
    return (
      <div>
        {(this.state.isFullScreen ||
          isLH(this.props.userAgent, this.props.hostname)) &&
          this.renderHeaderOld()}
        {isFrdyo(this.props.hostname)&&
          <div className="NotFound">
            <Helmet>
              <title>The page has disappeared</title>
              <body className="page-NotFound" />
            </Helmet>
            <div className="NotFound__imgFrdyo" />
            <div className="NotFound__txt">You're late, the page went on a trip！</div>
          </div>
        }
        {isHuna(this.props.hostname)&&
        <div className="NotFound">
          <Helmet>
            <title>huna </title>
            <body className="page-NotFound" />
          </Helmet>
          <div className="NotFound__imgHuna" />
          <div className="NotFound__txt">huna ！</div>
        </div>
        }
        {
          (isX(this.props.hostname)||isSocial(this.props.hostname))&&
          <div className="NotFound">
            <Helmet>
              <title>The page does not exist</title>
              <body className="page-NotFound" />
            </Helmet>
            <div className="NotFound__img" />
            <div className="NotFound__txt">The page you are visiting does not exist!</div>
          </div>
        }
      </div>
    )
  }
}

NotFound.propTypes = {
  userAgent: PT.string.isRequired,
  hostname: PT.string.isRequired,
}

export default connect((state, props) => {
  const {
    env: {userAgent, hostname},
  } = state
  return {
    userAgent,
    hostname,
  }
})(NotFound)
